<template>
  <v-card
    :tile="banner && !round"
    :flat="banner"
    :hover="!banner && !isRestricted"
    :color="withinAos ? getStoreThemeColor : '#efefef'"
    @click="openStore(store)"
    dark
  >
    <v-img
      class="white--text align-end image-banner"
      height="83"
      @error="() => (bannerSrcError = true)"
      :style="`${getBanner}${!withinAos ? 'filter: grayscale(1);' : ''}`"
    >
      <!-- :src="bannerSrcError ? 'inherit' : getBanner" -->
      <v-card-title class="text-h5">
        <v-avatar size="54" tile>
          <v-img
            @error="() => (logoSrcError = true)"
            v-if="!logoSrcError"
            :src="getLogo"
          ></v-img>
          <v-icon size="64" class="ma-0" v-if="logoSrcError">{{
            getNameInit
          }}</v-icon>
        </v-avatar>
        <span class="mx-2" style="text-shadow: -1px 1px 3px rgba(0,0,0,0.60);">{{ getName }}</span>
      </v-card-title>
      <div
        class="fill-height"
        style="
          width: 100%;
          position: absolute;
          background: linear-gradient(
            0deg,
            rgb(0 0 0 / 50%) -20%,
            rgba(0, 0, 0, 0) 15%
          );
          top: 0;
        "
      ></div>
    </v-img>
    <v-divider />
    <v-card-subtitle v-if="!banner" class="py-2" style="color: #00000095">{{
      store.description
    }}</v-card-subtitle>
    <v-card-actions v-if="!banner">
      <!-- <v-btn color="#00000060" depressed @click="openStore(store)">
        <v-icon small>mdi-store</v-icon>
        {{ $t("store.enter") }}
      </v-btn> -->
      <v-spacer />
      <v-chip small label color="#EEEEEE65" light class="px-1">
        <v-icon v-if="withinAos" color="#00000090"
          >mdi-map-marker-distance</v-icon
        >
        <v-icon v-if="!withinAos" color="#00000090"
          >mdi-map-marker-off-outline</v-icon
        >
        <span
          >{{
            getTravelTime({
              latitude: store.latitude,
              longitude: store.longitude,
            })
          }}ms</span
        >
      </v-chip>
    </v-card-actions>
    <div
      class="fill-height"
      style="
        pointer-events: none;
        width: 100%;
        position: absolute;
        background: linear-gradient(
          0deg,
          rgb(0 0 0 / 65%) -100%,
          rgba(0, 0, 0, 0) 30%
        );
        top: 0;
      "
    ></div>
    <v-fade-transition v-if="isRestricted">
      <v-overlay color="#ffffff" absolute :value="true">
        <v-chip dark :color="getStatusColor">
          {{ getStatus }}
        </v-chip>
      </v-overlay>
    </v-fade-transition>
  </v-card>
</template>

<script>
export default {
  name: "StoreCard",
  props: {
    store: Object,
    banner: Boolean,
    address: Object,
    round: Boolean,
  },
  data() {
    return {
      bannerSrcError: false,
      logoSrcError: false,
      status: {
        storeStatusOpen: "info",
        storeStatusClosed: "black",
        storeStatusPending: "warning",
        storeStatusSoon: "success",
        storeStatusTemporarilySuspended: "error",
      },
    };
  },
  computed: {
    isRestricted() {
      return this.store.statusName !== "STORE_STATUS_OPEN";
    },
    getStatus() {
      const statusKey = this.camelize(this.store.statusName);
      return this.isRestricted ? this.$t(`store.status.${statusKey}`) : "";
    },
    getStatusColor() {
      const statusKey = this.camelize(this.store.statusName);
      return this.status[statusKey];
    },
    getLogo() {
      return `${this.getApiBaseUrl}/files/image?path=stores/_${this.store.uuid}/logo.png`;
    },
    getBanner() {
      return this.bannerSrcError
        ? ""
        : `background-image: url(${this.getApiBaseUrl}/files/image?path=stores/_${this.store.uuid}/banner.png);`;
      // return this.store && !this.bannerSrcError
      // ? `${this.getApiBaseUrl}/files/image?path=stores/banners/_${this.store.uuid}.png`
      // : undefined;//"img/banner.png";
    },
    getName() {
      return this.store ? this.store.name : null;
    },
    getStoreThemeColor() {
      return this.store ? this.store.themeColor : null;
    },
    withinAos() {
      const { activeAddress: a } = this.$getUserInfo();
      console.log(a);
      return a && a.stores && a.stores.map((m) => m.id).includes(this.store.id);
    },
    getNameInit() {
      if (!this.store.name) {
        return "mdi-circle";
      }

      return `mdi-alpha-${this.store.name
        .substring(0, 1)
        .toLowerCase()}-circle`;
    },
  },
  methods: {
    openStore(store) {
      if(!store) {
        return;
      }
      
      this.$$router.push({
        name: "store",
        params: {
          storeId: store.id,
          store: store.name.replace(/\s/g, "-").toLowerCase(),
          back: { name: this.$route.name }
        },
      });
    },
    getTravelTime(p1) {
      if (this.address) {
        const { latitude, longitude } = this.address;
        const tt = this.$calcCrow(p1, { latitude, longitude });
        return Math.round((tt / 10) * 60, 2) + 10;
      }

      return "-";
    },
  },
};
</script>

<style>
.image-banner {
  background-position: center right !important;
}
</style>
