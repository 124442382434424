<template>
  <view-container scroll-fab>
    <div>
      <store-card :store="getStore" banner />
      <div class="px-3">
        <section-header
          :title="$t('store.sections')"
          icon="mdi-shape-outline"
          hide-divider
        />

        <v-row v-if="loading">
          <v-col xl="1" lg="2" md="4" sm="4" v-for="i in [1,2,3,4,5,6,7,8,9]" :key="i">
            <v-skeleton-loader
              type="card"
              class="shadow"
              min-width="100"
            ></v-skeleton-loader>
          </v-col>
        </v-row>

        <v-row v-else no-gutters>
          <v-col
            xl="1"
            lg="2"
            md="4"
            sm="4"
            v-for="(section, i) in getSections"
            :key="i"
            class="pa-1"
          >
            <section-card :section="section" />
          </v-col>
        </v-row>
        <section-header
          :title="$t('app.route.favorites')"
          icon="mdi-heart"
          class="mt-3"
        />
        <favorites-widget class="my-4" />
      </div>
    </div>

    <locked-cart store-items />
  </view-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StoreCard from "./StoreCard";
import SectionCard from "@/views/stores/SectionCard";
import FavoritesWidget from "@/views/stores/FavoritesWidget";
import ViewContainer from "@/common/layouts/ViewContainer";
import SectionHeader from "@/common/components/SectionHeader";
import LockedCart from "@/views/orders/LockedCart.vue";

export default {
  name: "OpenStore",
  components: {
    SectionCard,
    FavoritesWidget,
    SectionHeader,
    ViewContainer,
    StoreCard,
    LockedCart,
  },
  mounted() {
    this.loadSectionsByParentId(this.getStoreIdParam).then(() => this.loading = false);

    this.setReactivityParams([
      {
        resource: "SECTIONS",
        scope: "store",
        key: this.getStoreIdParam },
    ]);

    this.$sub("reactivity/SECTIONS", (payload) => {
      this.loading = true;
      if (payload.mode !== "Delete") {
        this.reloadSectionsByParams({
          storeId: this.getStoreIdParam,
          sectionIds: [payload.section],
        }).then(() => (this.loading = false));
      } else {
        this.removeSectionsFromState(payload.section).then(() => (this.loading = false));
      }
      console.log("reactivity/SECTIONS:", payload);
    });
  },
  beforeDestroy() {
    this.$unsubAll();
    this.clearReactivityParams();
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("sections", ["getSectionsByParentId"]),
    ...mapGetters("stores", ["getStoreById"]),

    getSections() {
      return this.getSectionsByParentId(this.getStoreIdParam);
    },
    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getStore() {
      return this.getStoreById(this.getStoreIdParam);
    },
  },
  methods: {
    ...mapActions("sections", [
      "loadSectionsByParentId",
      "loadSectionsByParams",
      "removeSectionsFromState",
      "reloadSectionsByParams",
    ]),
    ...mapActions("ui", ["setReactivityParams", "clearReactivityParams"]),
  },
};
</script>
