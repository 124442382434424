<template>
  <v-fade-transition v-if="cartLock || addressLock">
    <v-overlay :z-index="99" color="secondary" absolute :value="true">
      <v-card flat light class="pa-3">
        <div v-if="!loading">
          <v-alert dense color="secondary" max-width="350" icon="mdi-lock">
            <span v-if="cartLock">{{ $t("cart.messages.locked") }}</span>
            <span v-if="addressLock">{{
              $t("cart.messages.addressLocked")
            }}</span>
          </v-alert>

          <div v-if="cartLock" class="d-flex justify-space-between">
            <v-btn depressed @click="goToStoreCart">Original Store Cart</v-btn>
            <v-btn depressed color="error" @click="clearCart">Clear Cart</v-btn>
          </div>

          <v-divider v-if="cartLock && addressLock" />

          <div v-if="addressLock" class="d-flex justify-space-between">
            <v-btn depressed @click="goToAddresses">Go To Addresses</v-btn>
          </div>
        </div>

        <div
          class="fill-height"
          align-content="center"
          justify="center"
          v-if="loading"
          style="height: 7px; width: 100px;"
        >
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="7"
            :style="`background-color: ${getColor('secondary')};`"
          ></v-progress-linear>
        </div>
      </v-card>
    </v-overlay>
  </v-fade-transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LockedCart",
  props: {
    storeItems: {
      type: Boolean,
      default() {
        return true;
      },
    },
    address: Boolean,
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  computed: {
    ...mapGetters("ui", ["getColor"]),
    ...mapGetters("cart", [
      "getCartStoreId",
      "isCartLocked",
      "isAddressOutOfServiceLocked",
    ]),

    cartLock() {
      return this.storeItems && this.isCartLocked;
    },
    addressLock() {
      return this.address && this.isAddressOutOfServiceLocked;
    },
  },
  methods: {
    ...mapActions("cart", ["clearCart"]),

    goToStoreCart() {
      this.$$router.push({
        name: "cart",
        params: { storeId: this.getCartStoreId, back: { name: this.$route.name } },
      });
    },
    goToAddresses() {
      this.$$router.push({
        name: "addresses",
        params: { back: { name: this.$route.name }}
      });
    },
  },
};
</script>
