<template>
  <v-card
    color="white"
    hover
    :to="{ name: 'section', params: { storeId: section.store.id, store: getRouteName(section.store.name), section: getRouteName(section.name), sectionId: section.id } }"
  >
    <div class="d-flex justify-center grey lighten-5 py-2">
      <v-img
        :src="`${getApiBaseUrl}/files/image?path=stores/_${this.getStore.uuid}/${section.image}`"
        :max-width="isMd ? 90 : 130"
        min-width="90"
        min-height="130"
        aspect-ratio="16/9"
      ></v-img>
    </div>
    <v-card-title class="subtitle-1 pa-1 text-center" style="min-height: 50px; display: block; line-height: 20px;">
      {{ section.name }}
    </v-card-title>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "SectionCard",
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("stores", ["getStoreById"]),
    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getStore() {
      return this.getStoreById(this.getStoreIdParam);
    },
  }
};
</script>
